import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage, auth } from './firebase'; // Firebase setup
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Header from './Header';
import imageCompression from 'browser-image-compression'; // Import image compression library

function CreateProfilePage() {
  const { id } = useParams(); // Get the profile ID from the URL (if any)
  const navigate = useNavigate();
  const user = auth.currentUser;

  // Form states
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // New state for submission status
  const [driverName, setDriverName] = useState('');
  const [carModel, setCarModel] = useState('');
  const [description, setDescription] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [carPicture, setCarPicture] = useState(null);
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [youtubeVideo, setYoutubeVideo] = useState('');

  useEffect(() => {
    const fetchProfileData = async () => {
      if (id) {
        try {
          const docRef = doc(db, 'profiles', id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setProfileData(data);
            setDriverName(data.driverName || '');
            setCarModel(data.carModel || '');
            setDescription(data.description || '');
            setInstagram(data.socialMediaLinks?.instagram || '');
            setTwitter(data.socialMediaLinks?.twitter || '');
            setYoutubeVideo(data.youtubeVideo || '');
          } else {
            console.error('No profile found with this ID');
          }
        } catch (error) {
          console.error('Error fetching profile data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [id]);

  const handleImageCompression = async (file) => {
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      return file;
    }
  };

  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const compressedFile = await handleImageCompression(file);
      setProfilePicture(compressedFile);
    }
  };

  const handleCarPictureChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const compressedFile = await handleImageCompression(file);
      setCarPicture(compressedFile);
    }
  };
  const convertToEmbedURL = (youtubeURL) => {

    let embedURL = youtubeURL;
  
    // If the URL is in "youtu.be" format
    if (youtubeURL.includes('youtu.be')) {
      embedURL = youtubeURL.replace('youtu.be/', 'www.youtube.com/embed/');
    }
    
    // If the URL is in "youtube.com/watch?v=" format
    if (youtubeURL.includes('watch?v=')) {
      embedURL = youtubeURL.replace('watch?v=', 'embed/');
    }
  
    return embedURL;
  };
  const handleSubmit = async () => {
    if (!driverName || !carModel || !description || (!carPicture && !id)) {
      alert('Please fill in all required fields.');
      return;
    }
  
    setSubmitting(true); // Set submitting to true to show progress indicator
  
    try {
      let profileId = id; // Use existing ID if editing a profile
      const embedYoutubeURL = convertToEmbedURL(youtubeVideo);
      if (!id) {
        // Create a new profile in Firestore to get the ID
        const docRef = await addDoc(collection(db, 'profiles'), {
          userId: user.uid,
          driverName,
          carModel,
          description,
          profilePicture: null, // Placeholder for now
          carPicture: null, // Placeholder for now
          socialMediaLinks: {
            instagram,
            twitter,
          },
          youtubeVideo: embedYoutubeURL,
        });
        profileId = docRef.id; // Get the ID of the newly created document
      }
  
      // Upload images with the new profile ID
      const profilePicURL = profilePicture
        ? await uploadImage(profilePicture, `${profileId}-profile-pic.png`)
        : profileData?.profilePicture || `${process.env.PUBLIC_URL}/RaceDriverSilhouette.png`; // Replace with your default URL
  
      const carPicURL = carPicture
        ? await uploadImage(carPicture, `${profileId}-car-pic.png`)
        : profileData?.carPicture;
  
      // Update the profile with image URLs
      await updateDoc(doc(db, 'profiles', profileId), {
        userId: user.uid,
        driverName,
        carModel,
        description,
        profilePicture: profilePicURL,
        carPicture: carPicURL,
        socialMediaLinks: {
          instagram,
          twitter,
        },
        youtubeVideo: embedYoutubeURL,
      });
  
      console.log('Profile saved successfully!');
      navigate(`/car-details/${profileId}`); // Redirect to car details
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setSubmitting(false); // Reset submitting state
    }
  };
  

  const uploadImage = async (file, fileName) => {
    const fileRef = ref(storage, `uploads/${fileName}`);
    await uploadBytes(fileRef, file);
    return await getDownloadURL(fileRef);
  };

  if (loading) {
    return <p>Loading profile...</p>;
  }

  return (
    <div class="bg-gray-900 text-white">
      <Header />
      {auth.currentUser && id!=undefined && (
        <div className="mt-4 bg-gray-900 text-white">
          <button
            onClick={() => navigate('/car-details/' + id)}
            className="flex items-center space-x-2 text-white text-lg bg-transparent hover:bg-gray-800 transition duration-200 ease-in-out px-3 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 4a1 1 0 00-1.707-.707L1.586 10l6.707 6.707A1 1 0 0010 15.414l-5-5a1 1 0 010-1.414l5-5z"
                clipRule="evenodd"
              />
            </svg>
            <span>Back</span>
          </button>
        </div>
      )}
      {auth.currentUser && id==undefined && (
        <div className="mt-4 bg-gray-900 text-white">
          <button
            onClick={() => navigate('/user-profiles')}
            className="flex items-center space-x-2 text-white text-lg bg-transparent hover:bg-gray-800 transition duration-200 ease-in-out px-3 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 4a1 1 0 00-1.707-.707L1.586 10l6.707 6.707A1 1 0 0010 15.414l-5-5a1 1 0 010-1.414l5-5z"
                clipRule="evenodd"
              />
            </svg>
            <span>Back</span>
          </button>
        </div>
      )}
      <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center py-2">
        <div className="max-w-lg w-full bg-gray-800 shadow-lg rounded-lg p-8 border border-gray-700">
          <h2 className="text-4xl font-bold text-center mb-8">
            {id ? 'Edit Car Profile' : 'Create Car Profile'}
          </h2>

          {/* Driver Name */}
          <div className="mb-6">
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              Driver Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={driverName}
              onChange={(e) => setDriverName(e.target.value)}
              className="w-full px-4 py-2 text-black bg-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="Enter driver name"
              required
            />
          </div>

          {/* Car Model */}
          <div className="mb-6">
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              Car Model <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={carModel}
              onChange={(e) => setCarModel(e.target.value)}
              className="w-full px-4 py-2 text-black bg-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="Enter car model"
              required
            />
          </div>

          {/* Description */}
          <div className="mb-6">
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              Description <span className="text-red-500">*</span>
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-4 py-2 text-black bg-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="Enter a brief description"
              rows="3"
              required
            ></textarea>
          </div>

          {/* Profile Picture */}
          <div className="mb-6">
            {profileData?.profilePicture && (
              <div className="mb-4">
                <label className="block text-lg mb-2 font-semibold text-gray-300">
                  Existing Profile Picture
                </label>
                <img
                  src={profileData.profilePicture}
                  alt="Current profile"
                  className="w-32 h-32 rounded-full mx-auto object-cover"
                />
              </div>
            )}
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              Upload Profile Picture (Optional)
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              className="w-full text-gray-300"
            />
          </div>

          {/* Car Picture */}
          <div className="mb-6">
            {profileData?.carPicture && (
              <div className="mb-4">
                <label className="block text-lg mb-2 font-semibold text-gray-300">
                  Existing Car Picture
                </label>
                <img
                  src={profileData.carPicture}
                  alt="Current car"
                  className="w-full rounded-lg mb-4"
                />
              </div>
            )}
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              Upload Car Picture <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleCarPictureChange}
              className="w-full text-gray-300"
              required={!id} // Required only when creating a new profile
            />
          </div>

          {/* Social Media and YouTube Video Links */}
          <div className="mb-6">
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              Instagram Username (Optional)
            </label>
            <input
              type="url"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              className="w-full px-4 py-2 text-black bg-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="Instagram Username without the @"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              X (Twitter) Username (Optional)
            </label>
            <input
              type="url"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              className="w-full px-4 py-2 text-black bg-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="X (Twitter) Username without the @"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg mb-2 font-semibold text-gray-300">
              YouTube Video Link (Optional)
            </label>
            <input
              type="url"
              value={youtubeVideo}
              onChange={(e) => setYoutubeVideo(e.target.value)}
              className="w-full px-4 py-2 text-black bg-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="YouTube Video URL"
            />
          </div>

          {/* Submit Button */}
          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            disabled={submitting}
            className={`w-full ${submitting ? 'bg-gray-500' : 'bg-yellow-500 hover:bg-yellow-600'} 
              text-black font-bold py-3 rounded-lg shadow-lg transform hover:scale-105 transition-transform`}
          >
            {submitting ? 'Submitting...' : id ? 'Update Profile' : 'Create Profile'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateProfilePage;
