import React, { useState, useEffect } from 'react';
import { db } from './firebase'; // Import your Firebase setup
import { collection, getDocs, getDoc, deleteDoc, addDoc, updateDoc, doc, query, where } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react'; // Import QRCodeCanvas for generating QR codes
import sendInvitationEmail from './SendInvitation'; // A utility function we'll create
import Header from './Header';
import { FaEye, FaUsers } from 'react-icons/fa'; // Import icons

function TeamManagement() {
    const [team, setTeam] = useState(null);
    const [members, setMembers] = useState([]);
    const [carProfiles, setCarProfiles] = useState([]);
    const [emailToInvite, setEmailToInvite] = useState('');
    const [displayNameToInvite, setDisplayNameToInvite] = useState('');
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); // State for modal
    const { id } = useParams(); // Assuming you're using URL parameters
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [foregroundColor, setForegroundColor] = useState('#000000');
    const [scanStats, setScanStats] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTeamDetails = async () => {
            try {
                const docRef = doc(db, 'teams', id); // Get the document by ID
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const teamData = docSnap.data();
                    setTeam({ id: id, ...teamData });
                    setBackgroundColor(teamData.backgroundColor || '#ffffff');
                    setForegroundColor(teamData.foregroundColor || '#000000');
                    fetchMembers(id);
                    fetchCarProfiles(teamData.carProfiles);
                } else {
                    alert('Team not found');
                    navigate('/user-profiles');
                }
            } catch (error) {
                console.error('Error fetching team details:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchCarProfiles = async (carProfileIds) => {
            try {
                if (carProfileIds.length === 0) return;
                const profileQuery = query(
                    collection(db, 'profiles'),
                    where('__name__', 'in', carProfileIds)
                );
                const profileSnapshot = await getDocs(profileQuery);
                const profiles = profileSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCarProfiles(profiles);
                fetchScanStats(profiles);
            } catch (error) {
                console.error('Error fetching car profiles:', error);
            }
        };

        const fetchScanStats = async (profiles) => {
            const stats = {};
            for (const profile of profiles) {
                const statsDoc = await getDoc(doc(db, 'scanStats', profile.id));
                if (statsDoc.exists()) {
                    const data = statsDoc.data();
                    stats[profile.id] = {
                        totalScans: data.totalScans || 0,
                        uniqueVisitors: data.uniqueVisitors ? Object.keys(data.uniqueVisitors).length : 0,
                    };
                }
            }
            setScanStats(stats);
        };

        fetchTeamDetails();
    }, [id, navigate]);

    const fetchMembers = async (teamId) => {
        try {
            const invitationQuery = query(collection(db, 'invitations'), where('teamId', '==', teamId));
            const invitationSnapshot = await getDocs(invitationQuery);

            const teamMembers = invitationSnapshot.docs.map((invitationDoc) => {
                const invitation = {
                    id: invitationDoc.id,
                    ...invitationDoc.data(),
                };
                const displayName = invitation.status === 'accepted' && invitation.displayName
                    ? invitation.displayName
                    : 'Pending';

                return {
                    id: invitation.id,
                    email: invitation.email || 'Unknown',
                    displayName,
                    status: invitation.status || 'Pending',
                };
            });

            setMembers(teamMembers);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const handleColorChange = async (color, type) => {
        try {
            const teamRef = doc(db, 'teams', id);
            const updatedFields = type === 'background' ? { backgroundColor: color } : { foregroundColor: color };
            await updateDoc(teamRef, updatedFields);
            if (type === 'background') {
                setBackgroundColor(color);
            } else {
                setForegroundColor(color);
            }
        } catch (error) {
            console.error('Error updating team colors:', error);
            alert('Error updating colors. Please try again.');
        }
    };

    const handleInvite = async () => {
        if (!emailToInvite || !displayNameToInvite) {
            alert('Please enter a valid email and display name.');
            return;
        }

        try {
            const invitation = {
                teamId: id,
                email: emailToInvite,
                displayName: displayNameToInvite,
                status: 'pending',
                sentAt: new Date(),
            };
            const invitationRef = await addDoc(collection(db, 'invitations'), invitation);
            await sendInvitationEmail(emailToInvite, team.teamName, invitationRef.id);

            // alert(`Invitation sent to ${emailToInvite}`);
            setEmailToInvite('');
            setDisplayNameToInvite('');
            setShowModal(false); // Close the modal after sending the invitation
            fetchMembers(id); // Refresh members list
        } catch (error) {
            console.error('Error sending invitation:', error);
            alert('Error sending invitation. Please try again.');
        }
    };

    const handleDeleteMember = async (memberId) => {
        const confirmDelete = window.confirm('Are you sure you want to remove this member?');
        if (!confirmDelete) return;

        try {
            await deleteDoc(doc(db, 'invitations', memberId));
            // alert('Member removed successfully.')
            setMembers(members.filter(member => member.id !== memberId));
        } catch (error) {
            console.error('Error removing member:', error);
            alert('Error removing member. Please try again.');
        }
    };
    const handleShare = async () => {
        try {
            // Create a new canvas element for generating the image
            const canvas = document.createElement('canvas');
            canvas.width = 400;
            canvas.height = 500; // Adjust height to accommodate the logo
            const ctx = canvas.getContext('2d');

            // Draw a background color (optional, adjust as needed)
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Load and draw the team logo if available
            if (team?.logoURL) {
                const logoImage = new Image();
                logoImage.crossOrigin = 'anonymous'; // Prevent CORS issues
                logoImage.src = team.logoURL;

                await new Promise((resolve, reject) => {
                    logoImage.onload = () => {
                        // Adjust the size and position of the logo
                        const logoWidth = 100;
                        const logoHeight = 100;
                        const logoX = (canvas.width - logoWidth) / 2; // Center horizontally
                        const logoY = 20; // Adjust vertical position

                        ctx.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);
                        resolve();
                    };
                    logoImage.onerror = reject;
                });
            }

            // Generate and draw the QR code
            const qrCanvas = document.querySelector('canvas'); // Assuming there's a QR code canvas element
            if (qrCanvas) {
                ctx.drawImage(qrCanvas, 100, 150, 200, 200); // Adjust position and size as needed
            }

            // Add the "Share this Team" text
            ctx.fillStyle = '#000000';
            ctx.font = 'bold 16px Arial';
            ctx.textAlign = 'center';
            ctx.fillText('FanDrive Team', canvas.width / 2, 380);
            ctx.fillText('Scan to view details', canvas.width / 2, 400);

            // Convert the canvas to an image
            const imgData = canvas.toDataURL('image/png');
            const blob = await (await fetch(imgData)).blob();
            const file = new File([blob], 'team_qr.png', { type: 'image/png' });

            // Share the image using the Web Share API (if supported)
            if (navigator.canShare && navigator.canShare({ files: [file] })) {
                await navigator.share({
                    title: `Check out the ${team?.teamName} on FanDrive!`,
                    text: `Scan the QR code or click the link to view more details: ${teamURL}`,
                    files: [file],
                });
            } else {
                alert('Sharing is not supported on this device/browser.');
            }
        } catch (error) {
            console.error('Error sharing the image with QR code:', error);
        }
    };

    const teamURL = `https://www.fandrive.net/teams/${id}`;

    return (
        <div
            className="max-w-lg mx-auto rounded-lg shadow-md"
            style={{
                backgroundColor: backgroundColor,
                color: foregroundColor,
            }}
        >
            <div className="flex-none">
                <Header style={{ border: 'none' }} />
            </div>
            <div className="mt-4 bg-white text-black"
                style={{
                    backgroundColor: backgroundColor,
                    color: foregroundColor,
                }}>

                <button
                    onClick={() => navigate('/user-profiles')}
                    className="flex items-center space-x-2 text-black text-lg bg-transparent hover:bg-gray-800 transition duration-200 ease-in-out px-3 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill={foregroundColor}
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 4a1 1 0 00-1.707-.707L1.586 10l6.707 6.707A1 1 0 0010 15.414l-5-5a1 1 0 010-1.414l5-5z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span style={{
                        backgroundColor: backgroundColor,
                        color: foregroundColor,
                    }}>Back</span>
                </button>
            </div>
            <div className='p-4'>

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        <div className="mb-6 w-full max-w-lg mx-auto">
                            <img
                                src={team?.logoURL}
                                alt={`Team ${team?.teamName}`}
                                className="w-full max-w-xs h-auto mx-auto object-contain"
                                style={{
                                    maxHeight: '200px', // You can adjust the max height as needed
                                    maxWidth: '80%',   // Ensures the image doesn't exceed the container's width
                                }}
                            />
                        </div>
                        <div className="text-center">
                            <h2 className="text-2xl font-bold mb-4">{team?.teamName}</h2>
                        </div>
                        <div className="flex space-x-4 mt-6">
                            <div>
                                <label className="block text-sm font-medium mb-1">Background Color</label>
                                <input
                                    type="color"
                                    value={backgroundColor}
                                    onChange={(e) => handleColorChange(e.target.value, 'background')}
                                    className="mb-4"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1">Foreground Color</label>
                                <input
                                    type="color"
                                    value={foregroundColor}
                                    onChange={(e) => handleColorChange(e.target.value, 'foreground')}
                                    className="mb-4"
                                />
                            </div>
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Team Members</h3>
                        {members.length > 0 ? (
                            <ul>
                                {members.map((member) => (
                                    <li key={member.id} className="mb-2 flex justify-between items-center">
                                        <span className="truncate">{member.displayName} ({member.email})</span> {/* Ensure text truncation */}
                                        <button
                                            onClick={() => handleDeleteMember(member.id)}
                                            className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="w-5 h-5"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No members in this team yet.</p>
                        )}
                        <div className="mt-4 text-center">
                            <button
                                onClick={() => setShowModal(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                            >
                                Add Member
                            </button>
                        </div>
                        <div className="mt-6 mb-6">
                            <h3 className="text-center text-xl font-semibold mt-6 mb-2">Team Cars</h3>
                            {carProfiles.length > 0 ? (
                                <div className="grid grid-cols-1 gap-4">
                                    {carProfiles.map((profile) => (
                                        <div
                                            key={profile.id}
                                            className="bg-white rounded-lg p-4 border border-gray-300 flex flex-col items-start cursor-pointer"
                                            onClick={() => navigate(`/car-details/${profile.id}`, { state: { fromTeamManagement: true, teamId: id } })}
                                        >
                                            <h4 className="text-lg font-semibold mb-2">{profile.carModel}</h4>
                                            <div className="flex items-center">
                                                <img
                                                    src={profile.carPicture}
                                                    alt="Car Thumbnail"
                                                    className="w-20 h-20 object-cover rounded mr-4"
                                                />
                                                <div className="flex-1">
                                                    <p className="text-sm">Driver: {profile.driverName}</p>
                                                    {scanStats[profile.id] && (
                                                        <div className="mt-2 flex items-center space-x-4 text-xs">
                                                            <div className="flex items-center">
                                                                <FaEye className="text-yellow-500 mr-1" />
                                                                <span>{scanStats[profile.id].totalScans || 0} Views</span>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <FaUsers className="text-yellow-500 mr-1" />
                                                                <span>{scanStats[profile.id].uniqueVisitors || 0} Unique Visitors</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-center">No car profiles associated with this team.</p>
                            )}
                        </div>
                        <div className="text-center">
                            {/* <h2 className="text-2xl font-bold mb-4">{team?.teamName}</h2> */}
                            <QRCodeCanvas value={teamURL} size={200} className="mx-auto mb-4" />
                            <button
                                onClick={handleShare}
                                className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 mt-4"
                            >
                                Share Team
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
                        <h3 className="text-lg font-semibold mb-4">Invite Member</h3>
                        <input
                            type="text"
                            placeholder="Enter display name"
                            value={displayNameToInvite}
                            onChange={(e) => setDisplayNameToInvite(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg mb-2"
                        />
                        <input
                            type="email"
                            placeholder="Enter email to invite"
                            value={emailToInvite}
                            onChange={(e) => setEmailToInvite(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg mb-2"
                        />
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleInvite}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                            >
                                Send Invitation
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TeamManagement;