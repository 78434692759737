import React, { useState } from 'react';
import { db, storage, auth } from './firebase'; // Adjust import paths if necessary
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import imageCompression from 'browser-image-compression'; // Import image compression library


function TeamProfile() {
    const [teamName, setTeamName] = useState('');
    const [teamDescription, setTeamDescription] = useState('');
    const [teamLogo, setTeamLogo] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Default background color
    const [foregroundColor, setForegroundColor] = useState('#000000'); // Default foreground color
    const [loading, setLoading] = useState(false);
    const [teamWebsite, setTeamWebsite] = useState(''); // Add state for website URL

    const navigate = useNavigate();

    const handleImageCompression = async (file) => {
        try {
            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing image:', error);
            return file;
        }
    };

    const handleLogoChange = async (event) => {
        if (event.target.files[0]) {
            const compressedFile = await handleImageCompression(event.target.files[0]);
            setTeamLogo(compressedFile);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            let logoURL = '';
            if (teamLogo) {
                const storageRef = ref(storage, `team-logos/${teamName}-${Date.now()}`);
                await uploadBytes(storageRef, teamLogo);
                logoURL = await getDownloadURL(storageRef);
            }

            // Get the current user
            const currentUser = auth.currentUser;
            if (!currentUser) {
                throw new Error('You must be logged in to create a team.');
            }

            const newTeam = {
                teamName,
                teamDescription,
                logoURL,
                website: teamWebsite, // Save the website URL
                backgroundColor,
                foregroundColor,
                createdAt: new Date(),
                ownerId: currentUser.uid, // Set the ownerId to the current user's ID
            };

            await addDoc(collection(db, 'teams'), newTeam);

            alert('Team created successfully!');
            navigate('/user-profiles'); // Redirect to UserProfiles or another page after creation
        } catch (error) {
            console.error('Error creating team:', error);
            alert('Error creating team. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md">
            <div className="flex-none">
                <Header style={{ border: 'none' }} />
            </div>
            <div className="mt-4 bg-white text-black">

                <button
                    onClick={() => navigate('/user-profiles')}
                    className="flex items-center space-x-2 text-black text-lg bg-transparent hover:bg-gray-800 transition duration-200 ease-in-out px-3 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 4a1 1 0 00-1.707-.707L1.586 10l6.707 6.707A1 1 0 0010 15.414l-5-5a1 1 0 010-1.414l5-5z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span>Back</span>
                </button>
            </div>
            <div className='p-4'>
                <h2 className="text-2xl font-bold mb-4">Create a New Team</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="teamName" className="block text-sm font-medium text-gray-700 mb-2">
                            Team Name
                        </label>
                        <input
                            type="text"
                            id="teamName"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            required
                            className="w-full px-3 py-2 border rounded-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="teamDescription" className="block text-sm font-medium text-gray-700 mb-2">
                            Team Description
                        </label>
                        <textarea
                            id="teamDescription"
                            value={teamDescription}
                            onChange={(e) => setTeamDescription(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg"
                            rows="4"
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="teamWebsite" className="block text-sm font-medium text-gray-700 mb-2">
                            Team Website URL
                        </label>
                        <input
                            type="url"
                            id="teamWebsite"
                            value={teamWebsite}
                            onChange={(e) => setTeamWebsite(e.target.value)}
                            placeholder="https://example.com"
                            className="w-full px-3 py-2 border rounded-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="teamLogo" className="block text-sm font-medium text-gray-700 mb-2">
                            Team Logo
                        </label>
                        <input
                            type="file"
                            id="teamLogo"
                            accept="image/*"
                            onChange={handleLogoChange}
                            className="w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="backgroundColor" className="block text-sm font-medium text-gray-700 mb-2">
                            Background Color
                        </label>
                        <input
                            type="color"
                            id="backgroundColor"
                            value={backgroundColor}
                            onChange={(e) => setBackgroundColor(e.target.value)}
                            className="w-12 h-12 p-0 border-0"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="foregroundColor" className="block text-sm font-medium text-gray-700 mb-2">
                            Foreground Color
                        </label>
                        <input
                            type="color"
                            id="foregroundColor"
                            value={foregroundColor}
                            onChange={(e) => setForegroundColor(e.target.value)}
                            className="w-12 h-12 p-0 border-0"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Team'}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default TeamProfile;