import React, { useState } from 'react';
import { X, Plus, Clock, Calendar } from 'lucide-react';

const TIMEZONES = [
  "UTC",
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
  "Europe/London",
  "Europe/Paris",
  "Asia/Tokyo",
  "Australia/Sydney"
];

const getDayLabel = (startDate, dayIndex, timezone) => {
  const [year, month, day] = startDate.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() + dayIndex);
  const formatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    timeZone: timezone,
  });
  return formatter.format(date);
};

const ScheduleBuilder = () => {
  const [scheduleName, setScheduleName] = useState('New Schedule');
  const [days, setDays] = useState(2);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [raceClasses, setRaceClasses] = useState([
    { name: 'GT3', color: '#2563eb' },
    { name: 'GT4', color: '#16a34a' }
  ]);
  const [sessionTypes, setSessionTypes] = useState(['Practice', 'Qualifying', 'Race']);
  const [schedule, setSchedule] = useState([]);
  const [newClassName, setNewClassName] = useState('');
  const [newClassColor, setNewClassColor] = useState('#000000');
  const [newSessionType, setNewSessionType] = useState('');
  const [newSession, setNewSession] = useState({
    day: 1,
    startHour: '07',
    startMinute: '00',
    duration: 30,
    raceClass: '',
    sessionType: 'Practice'
  });

  const timeToMinutes = (hour, minute) => parseInt(hour) * 60 + parseInt(minute);
  const minutesToTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60) % 24;
    const minutes = totalMinutes % 60;
    return { hours: hours.toString().padStart(2, '0'), minutes: minutes.toString().padStart(2, '0') };
  };

  const formatTime = (hours, minutes) => `${hours}:${minutes}`;
  const calculateEndTimeInMinutes = (startHour, startMinute, duration) =>
    timeToMinutes(startHour, startMinute) + parseInt(duration);

  const calculateEndTime = (startHour, startMinute, duration) => {
    const endMinutes = calculateEndTimeInMinutes(startHour, startMinute, duration);
    const { hours, minutes } = minutesToTime(endMinutes);
    return formatTime(hours, minutes);
  };

  const findNextAvailableTime = (day) => {
    const daySchedule = schedule
      .filter(session => session.day === day)
      .map(session => ({
        start: timeToMinutes(session.startHour, session.startMinute),
        end: calculateEndTimeInMinutes(session.startHour, session.startMinute, session.duration)
      }))
      .sort((a, b) => a.start - b.start);

    let nextStart = 0;
    for (let session of daySchedule) {
      if (nextStart < session.start) break;
      nextStart = session.end;
    }
    return minutesToTime(nextStart);
  };

  const addSessionToNextAvailable = () => {
    if (!newSession.raceClass) {
      alert('Please select a race class');
      return;
    }
  
    // Get all sessions for the selected day
    const daySchedule = schedule
      .filter(session => session.day === newSession.day)
      .map(session => ({
        start: timeToMinutes(session.startHour, session.startMinute),
        end: calculateEndTimeInMinutes(session.startHour, session.startMinute, session.duration),
      }))
      .sort((a, b) => a.start - b.start);
  
    // Determine the start time for the new session
    let newStartTime;
    if (daySchedule.length === 0) {
      // No sessions exist; use the current startHour and startMinute
      newStartTime = {
        hours: newSession.startHour,
        minutes: newSession.startMinute,
      };
    } else {
      // Sessions exist; calculate the end time of the last session
      const lastSession = daySchedule[daySchedule.length - 1];
      newStartTime = minutesToTime(lastSession.end);
    }
  
    // Add the session
    const updatedSession = { 
      ...newSession, 
      startHour: newStartTime.hours, 
      startMinute: newStartTime.minutes 
    };
    setSchedule([...schedule, updatedSession]);
  };

  const addSessionType = () => {
    if (newSessionType.trim() && !sessionTypes.includes(newSessionType)) {
      setSessionTypes([...sessionTypes, newSessionType]);
      setNewSessionType('');
    }
  };

  const removeSessionType = (type) => {
    setSessionTypes(sessionTypes.filter(t => t !== type));
    setSchedule(schedule.filter(session => session.sessionType !== type));
  };

  const addClass = () => {
    if (newClassName.trim()) {
      setRaceClasses([...raceClasses, { name: newClassName, color: newClassColor }]);
      setNewClassName('');
      setNewClassColor('#000000');
    }
  };

  const removeClass = (className) => {
    setRaceClasses(raceClasses.filter(rc => rc.name !== className));
    setSchedule(schedule.filter(session => session.raceClass !== className));
  };

  const addSession = () => {
    if (!newSession.raceClass) {
      alert('Please select a race class');
      return;
    }
    setSchedule([...schedule, { ...newSession }]);
  };

  const removeSession = (index) => {
    setSchedule(schedule.filter((_, i) => i !== index));
  };

  return (
    <div className="p-4 space-y-6 max-w-7xl mx-auto">
      {/* Schedule Name Section */}
      <div className="p-4 border rounded shadow">
        <label className="block font-bold mb-2">Schedule Name:</label>
        <input
          type="text"
          value={scheduleName}
          onChange={(e) => setScheduleName(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      {/* Configuration Section */}
      <div className="grid gap-6 md:grid-cols-2">
        <div className="p-4 border rounded shadow">
          <h2 className="text-lg font-bold mb-4 flex items-center gap-2">
            <Calendar className="w-5 h-5" />
            Schedule Configuration
          </h2>
          <div className="grid gap-4">
            <div className="flex items-center gap-2">
              <label>Race Days:</label>
              <input
                type="number"
                min="1"
                max="5"
                value={days}
                onChange={(e) => setDays(parseInt(e.target.value))}
                className="border p-2 rounded w-20"
              />
            </div>
            <div className="flex items-center gap-2">
              <label>Start Date:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border p-2 rounded w-40"
              />
            </div>
            <div className="flex items-center gap-2">
              <label>Time Zone:</label>
              <select
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
                className="border p-2 rounded w-full"
              >
                {TIMEZONES.map(tz => (
                  <option key={tz} value={tz}>{tz.replace('_', ' ')}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Manage Session Types */}
        <div className="p-4 border rounded shadow">
          <h2 className="font-bold mb-4">Manage Session Types</h2>
          <div className="flex items-center gap-2 mb-4">
            <input
              placeholder="New Session Type"
              value={newSessionType}
              onChange={(e) => setNewSessionType(e.target.value)}
              className="flex-1 border p-2 rounded"
            />
            <button
              onClick={addSessionType}
              className="bg-green-500 text-white p-2 rounded"
            >
              <Plus size={16} />
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {sessionTypes.map((type, index) => (
              <div
                key={index}
                className="p-2 bg-gray-200 rounded flex items-center gap-2"
              >
                <span>{type}</span>
                <button
                  onClick={() => removeSessionType(type)}
                  className="text-red-500"
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Add Race Classes */}
      <div className="p-4 border rounded shadow">
        <h2 className="font-bold mb-4">Race Classes</h2>
        <div className="flex flex-wrap gap-2 mb-4">
          <div className="flex gap-2 items-center flex-1">
            <input
              placeholder="Class Name"
              value={newClassName}
              onChange={(e) => setNewClassName(e.target.value)}
              className="border p-2 rounded w-full"
            />
            <input
              type="color"
              value={newClassColor}
              onChange={(e) => setNewClassColor(e.target.value)}
              className="w-20"
            />
            <button
              onClick={addClass}
              className="p-2 bg-blue-500 text-white rounded"
            >
              <Plus className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {raceClasses.map((rc, i) => (
            <div
              key={i}
              className="px-3 py-1.5 rounded-full flex items-center gap-2 shadow-sm"
              style={{ backgroundColor: rc.color }}
            >
              <span className="text-white font-medium">{rc.name}</span>
              <button
                onClick={() => removeClass(rc.name)}
                className="hover:bg-white/20 rounded-full p-1 transition-colors"
              >
                <X size={14} className="text-white" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Add Session Section */}
      <div className="p-4 border rounded shadow">
        <h2 className="text-lg font-bold mb-4 flex items-center gap-2">
          <Clock className="w-5 h-5" />
          Add Session
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-4">
          <div>
            <label className="block text-sm mb-1 text-gray-600">Day</label>
            <input
              type="number"
              min="1"
              max={days}
              value={newSession.day}
              onChange={(e) => setNewSession({ ...newSession, day: parseInt(e.target.value) })}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm mb-1 text-gray-600">Hour</label>
            <input
              type="number"
              min="0"
              max="23"
              value={newSession.startHour}
              onChange={(e) => setNewSession({ ...newSession, startHour: e.target.value.padStart(2, '0') })}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm mb-1 text-gray-600">Minute</label>
            <input
              type="number"
              min="0"
              max="59"
              value={newSession.startMinute}
              onChange={(e) => setNewSession({ ...newSession, startMinute: e.target.value.padStart(2, '0') })}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm mb-1 text-gray-600">Duration (min)</label>
            <input
              type="number"
              min="1"
              value={newSession.duration}
              onChange={(e) => setNewSession({ ...newSession, duration: parseInt(e.target.value) })}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm mb-1 text-gray-600">Class</label>
            <select
              className="border p-2 rounded w-full"
              value={newSession.raceClass}
              onChange={(e) => setNewSession({ ...newSession, raceClass: e.target.value })}
            >
              <option value="">Select Class</option>
              {raceClasses.map(rc => (
                <option key={rc.name} value={rc.name}>{rc.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm mb-1 text-gray-600">Type</label>
            <select
              className="border p-2 rounded w-full"
              value={newSession.sessionType}
              onChange={(e) => setNewSession({ ...newSession, sessionType: e.target.value })}
            >
              {sessionTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>
        <button
          onClick={addSession}
          className="w-full p-2 bg-green-500 text-white rounded mt-2"
        >
          Add Session
        </button>
        <button
          onClick={addSessionToNextAvailable}
          className="w-full p-2 bg-blue-500 text-white rounded mt-2"
        >
          Add to Next Available Time
        </button>
      </div>

      {/* Session Details Section */}
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {Array.from({ length: days }, (_, day) => (
          <div key={day} className="p-4 border rounded shadow">
            <h3 className="font-bold mb-4 pb-2 border-b flex justify-between items-center">
              {getDayLabel(startDate, day, timezone)}
              <span className="text-sm font-normal text-gray-500">
                {schedule.filter(s => s.day === day + 1).length} sessions
              </span>
            </h3>
            <div className="space-y-2">
              {schedule
                .filter(session => session.day === day + 1)
                .sort((a, b) => timeToMinutes(a.startHour, a.startMinute) - timeToMinutes(b.startHour, b.startMinute))
                .map((session, index) => {
                  const raceClass = raceClasses.find(rc => rc.name === session.raceClass);
                  return (
                    <div
                      key={index}
                      className="rounded-lg shadow-sm p-2"
                      style={{ backgroundColor: raceClass?.color }}
                    >
                      <div className="flex justify-between items-center text-white">
                        <div className="flex-1">
                          <div className="font-medium">
                            {formatTime(session.startHour, session.startMinute)} - 
                            {calculateEndTime(session.startHour, session.startMinute, session.duration)}
                          </div>
                          <div className="text-sm opacity-90">
                            {session.raceClass} - {session.sessionType}
                          </div>
                          <div className="text-xs opacity-75">
                            {session.duration} minutes
                          </div>
                        </div>
                        <button
                          onClick={() => removeSession(schedule.indexOf(session))}
                          className="hover:bg-white/20 rounded-full p-1.5 transition-colors"
                        >
                          <X size={14} />
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleBuilder;